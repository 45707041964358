import React from 'react'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom'

import './styles.css';

import Dashboard from './Dashboard';
import AddNewDevice from './AddNewDevice';
import Settings from './Settings';
import Loading from './Loading';

var firebase = require('./firebase');

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null
    }
  }

  componentDidMount() {
    firebase.auth().getRedirectResult().then(function(result) {
      if (result.credential) {
        console.log('User is signed in as ', firebase.auth().user.email);
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
      }
    }).catch(function(error) {
      console.log('Auth error', error.message, error.code, error.email);
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.setState({isLoggedIn: true})
        console.log('User is now signed in as ', user.email);
      } else {
        this.setState({isLoggedIn: false})
        console.log('Signed out.');
      }
    });
  }

  render() {

    if (this.state.isLoggedIn == null) {
      return <Loading />
    }

    return (
      <React.Fragment>
          <Router>
            <Layout>
              <main>
                <Switch>
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn}
                                exact path='/'
                                component={Dashboard} />
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn}
                                exact path='/new'
                                component={AddNewDevice} />
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn}
                                exact path='/settings'
                                component={Settings} />
                  <Route path='/sign-in' component={SignIn}/>
                </Switch>
              </main>
            </Layout>
          </Router>
      </React.Fragment>
    )
  }
}

const Layout = (props) => {
  return (
    <React.Fragment>
      <header>
        <div className="wrapper">
          <h1><Link to="/">weatherbox</Link></h1>
        </div>
      </header>
      {props.children}
    </React.Fragment>
  )
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      authListenerUnsubscribe: null
    }
  }

  handleClick() {
    if (!firebase.auth().currentUser) {
      console.log('not logged in');
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('email');
      firebase.auth().signInWithRedirect(provider);
      console.log('done')
    }
  }

  componentDidMount() {
    firebase.auth().getRedirectResult().then(function(result) {
      if (result.credential) {
        console.log('User is signed in as ', firebase.auth().user.email);
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
      }
    }).catch(function(error) {
      // Handle Errors here.
      // console.log('Auth error', error.message, error.code, error.email);
    });

    this.setState({
      authListenerUnsubscribe: firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          this.setState({isLoggedIn: true})
          console.log('User is now signed in as ', user.email);
        } else {
          console.log('Signed out.');
          this.setState({isLoggedIn: false})
        }
      })
    });
  }

  componentWillUnmount() {
    //  Unsubscribe from the auth the listener.
    this.state.authListenerUnsubscribe();
  }

  render() {

    if (this.state.isLoggedIn === true) {
      return <Redirect to='/' />
    }

    return (
      <React.Fragment>
        <header>
          <div className="wrapper">
            <h2>welcome</h2>
          </div>
        </header>
        <div className="wrapper">
          <p>Click below to log in to view your devices.</p>
          <button id="sign-in" onClick={this.handleClick}>Sign in with Google</button>
        </div>
      </React.Fragment>
    )
  }
}

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLoggedIn === true
      ? <Component {...props} />
      : <Redirect to='/sign-in' />
  )} />
)

export default Main;
