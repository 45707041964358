// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";

const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

var config = {
  apiKey: "AIzaSyAbyKgswpI0oezSOVSGxJzzJ0xsYtE5SyI",
  authDomain: "weatherbox-f9717.firebaseapp.com",
  databaseURL: "https://weatherbox-f9717.firebaseio.com/",
  projectId: "weatherbox-f9717"
};

firebase.initializeApp(config);

module.exports = {
  fsDb: firebase.firestore(),
  fbDb: firebase.database(),
  auth: firebase.auth,
  Firestore: firebase.firestore
}
