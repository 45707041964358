import React from 'react';
import Loading from './Loading';
import {Link, Redirect} from 'react-router-dom';
const firebase = require('./firebase');
const momentForecastUpdated = require('moment');

momentForecastUpdated.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : 'a second',
        ss : '%ds',
        m:  "1m",
        mm: "%dm",
        h:  "1hr",
        hh: "%dhrs",
        d:  "1d",
        dd: "%dd",
        w:  "a week",
        ww: "%dwks",
        M:  "a month",
        MM: "%dmo",
        y:  "a year",
        yy: "%dy"
    }
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      devices: {},
      showEditControls: false,
      noDevices: false,
      user: firebase.auth().currentUser,
      listeners: []
    }
  }

  componentDidMount() {
    console.log('Checking for user devices')

    // Fetch a list of user's devices.
    const userDocRef = firebase.fsDb.collection('users').doc(this.state.user.uid);
    userDocRef.onSnapshot(userSnapshot => {
      const userDevices = userSnapshot.get('devices');
      if (userSnapshot.exists && userDevices) {
        console.log(`Found ${userDevices.length} for user ${this.state.user.uid}.`);

        // Get the data for each device.
        userDevices.forEach((deviceId, i) => {

          const deviceDocRef = firebase.fsDb.collection('devices').doc(deviceId);
          const listener = deviceDocRef.onSnapshot(deviceSnapshot => {

            // Add device info to the state.
            let updatedDevices = this.state.devices
            updatedDevices[deviceId] = deviceSnapshot.data()
            this.setState({ devices: updatedDevices }, () => {

              // Check if all of user's devices have been fetched.
              if (i === (userDevices.length - 1)) {
                console.log(`Data udpated for device ${deviceId}`)
                console.log(this.state.devices)
                this.setState({devicesDataLoaded: true});
              }
            })
          })

          // Keep track of Firestore listeners so they can be unsubscribed.
          const listeners = this.state.listeners;
          listeners.push(listener);
          this.setState({listeners: listeners})
        })

      // If there are no devices associated with this user.
      } else {
        console.log('No devices associated with user.');
        this.setState({
          noDevices: true,
          devicesDataLoaded: true
        });
      }
    })
  }

  componentWillUnmount() {
    console.log('Removing listeners')
    this.state.listeners.forEach(listener => {
      listener();
    })
  }

  // Toggle the edit controls
  handleAddDeviceClick() {
    return <Redirect to="/new" />
  }

  handleLogoutClick() {
    firebase.auth().signOut();
  }

  render() {

    // Dasboard rendering is blocked until dataLoaded is true.
    if (!this.state.devicesDataLoaded) {
      // return <div />
      return <Loading />
    }

    if (this.state.devicesDataLoaded && this.state.noDevices) {
      console.log('No devices.')
      return <Redirect to="/new" />
    }

    let devicesToRender = [];
    Object.keys(this.state.devices).forEach((deviceId, i) => {
      const device = this.state.devices[deviceId];
      devicesToRender.push(
        <Device key={`device-${i}`}
                deviceId={deviceId}
                location={device.location}
                deviceName={device.name}
                lastUpdated={device.deviceLastUpdatedTimestamp}
                isUpdatingForecast={device.updatingForecast}
        />
      );
    })

    return (
      <React.Fragment>
        <header>
          <div className="wrapper">
            <h2>dashboard</h2>
            <Link to="/new" className="small secondary button">+ add</Link>
          </div>
        </header>
        <div className="wrapper">
          <ul id="devices">
            {devicesToRender}
          </ul>
          <footer>
            <p>Need help? Visit the <Link to="/help">help page</Link>.</p>
            <p id="signed-in-email">Signed in as {firebase.auth().currentUser.email}.</p>
            <p><button className="tertiary"
                    id="sign-out"
                    onClick={this.handleLogoutClick}
            >Sign&nbsp;Out</button></p>
          </footer>
        </div>
    </React.Fragment>
    );
  }
}

class Device extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heartbeatRef: firebase.fbDb.ref(`heartbeat/${this.props.deviceId}`),
      deviceDoc: firebase.fsDb.collection('devices').doc(this.props.deviceId)
    }
  }

  checkHeartRate = () => {
    if (this.state.lastHeartbeatTimestamp) {
      const then = momentForecastUpdated(this.state.lastHeartbeatTimestamp * 1000);
      const now = momentForecastUpdated(Date.now());
      // Update device status to "0", or "offline", if it hasn't pinged for 15s.
      if (now.diff(then) > 5000) {
        console.log('No response for 5seconds, setting statusCode=2')
        this.setState({ statusCode: 0 });
      } else if (this.props.isUpdatingForecast === 1) {
        this.setState({ statusCode: 1 });
      } else {
        this.setState({ statusCode: 2 });
      }
    }
  }

  createDeviceStatus = () => {
    let lastUpdatedString;
    if (this.props.lastUpdated) {
      const then = momentForecastUpdated(this.props.lastUpdated * 1000);
      const now = momentForecastUpdated(Date.now());
      lastUpdatedString = then.from(now);
    }

    let statusDescription;
    // First check if device hasn't been responsive for more than 5s.
    if (this.state.statusCode === 0) {
      statusDescription = `Offline · Last seen ${lastUpdatedString}`;
    // If device is online, check if the forecast is updating.
    } else if (this.state.statusCode === 1) {
      statusDescription = 'Updating forecast...';
    // If device is online, check if the forecast is updating.
    } else if (this.state.statusCode === 2) {
      if (!this.props.location) {
        statusDescription = 'Online · Waiting for location...';
      } else {
        statusDescription = `Online · Forecast updated ${lastUpdatedString}`;
      }
    } else {
      statusDescription = 'Loading...';
    }

    let heartbeatClasses = `heartbeat status-code-${this.state.statusCode} ` + (this.state.blinkOn ? " blink-on" : "")

    return (
      <React.Fragment>
        <span className={heartbeatClasses}
             id={"heartbeat-" + this.props.deviceId}
        ></span>
        <span className="status-description">{statusDescription}</span>
      </React.Fragment>
    )
  }

  createLinkProps = () => {
    return {
      pathname: '/settings',
      state: {
        deviceId: this.props.deviceId,
        backNav: "back to device list"
      }
    }
  }

  blinkOn = () => {
    this.setState({
      blinkOn: true
    }, () => {
      window.setTimeout(this.blinkOff, 100)
    })
  }

  blinkOff = () => {
    this.setState({
      blinkOn: false
    })
  }

  componentDidMount() {
    // Listen for heartrate and save timestamp.
    this.state.heartbeatRef.on('value', snapshot => {
      this.setState({
        lastHeartbeatTimestamp: snapshot.val().timestamp
      }, () => {
        this.blinkOn();
      })
    })

    // Check heartrate every second to see if device is still online.
    const lastHeartbeatTimestampInterval = window.setInterval(this.checkHeartRate, 1);
    this.setState({
      lastHeartbeatTimestampInterval: lastHeartbeatTimestampInterval,
      dataLoaded: true
    })
  }

  componentWillUnmount() {
    // Turn off heartrate listener.
    this.state.heartbeatRef.off('value');

    // Clear all alive ping check.
    window.clearInterval(this.state.lastHeartbeatTimestampInterval)
  }

  render() {
    // Don't render if device data hasn't been loaded yet.

    if (!this.state.dataLoaded) {
      return <div />;
    }

    return (
      <li
        className="device"
        key={this.props.deviceId}
      >
        <Link to={this.createLinkProps()}>
          <div className="device-info">
            <p className="device-name">{this.props.deviceName}</p>
            <p className="device-location">{this.props.location}</p>
            <p className="device-status">{this.createDeviceStatus()}</p>
          </div>
        </Link>
      </li>
    )
  }
}

export default Dashboard;
