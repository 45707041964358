import React from 'react';
import firebase from './firebase.js';
import {Link, Redirect} from 'react-router-dom';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    // Only initialize the state of the settings page if props.location.state
    // is set, as this means that data was sent from the dashboard.
    //
    // If props.location.state is not set, then this page was likely loaded
    // from the browser's URL bar, and will be redirected back to the dashboard.
    //
    // The redirect is set via the state, and triggered in the render function.
    if (props.location.state !== undefined) {
      this.state = {
        settingsData: null,
        locationStateExists: true,
        devices: null,
        dataLoaded: false,
        deviceId: props.location.state.deviceId,
        deviceKey: this.props.location.state.deviceKey,
        deviceRef: firebase.fsDb.collection('devices').doc(props.location.state.deviceId),
        listener: null
      }
    } else {
      // Without a location state, redirect back to dashboard.
      this.state = { redirect: true }
    }
  }

  getDeviceInfo = () => {
    const listener = this.state.deviceRef.onSnapshot(deviceSnapshot => {
      const device = deviceSnapshot.data()
      this.setState({ lastUpdated: device.lastUpdated} );
      this.setState({ deviceIp: device.ip });
      this.setState({ location: device.location });
      this.setState({ timezone: device.timezone });
      this.setState({ update: device.update });
      this.setState({ deviceName: device.name });
      this.setState({ zoom: device.zoom });

      // The descriptive address string for every geocoding is helpful info to
      // provide context about the device's current location.
      const locationRef = firebase.fsDb.collection('coordinates').doc(device['location']);
      locationRef.get().then(snapshot => {
        this.setState({address: snapshot.get('address')}, () => {
          this.setState({ dataLoaded: true })
        });
      });
    });

    this.setState({listener: listener})
  }

  componentDidMount() {
    // If a redirect has been set, do not get device info from database.
    if (!this.state.redirect) {
      this.getDeviceInfo();
    }
  }

  componentWillUnmount() {
    this.state.listener();
  }

  createSettings = () => {
    const displayDeviceId = this.state.deviceId.substring(0, 4) + '-' + this.state.deviceId.substring(4);
    const deviceInfo = displayDeviceId + " · " + this.state.deviceIp;
    const timezones = [
      'America/Los_Angeles',
      'America/Denver',
      'America/Chicago',
      'America/New_York'
    ]
    const updateOptions = [
      'No updates scheduled',
      'Update on next reboot'
    ]
    const twelveHourZoom = [
      '24-hour',
      '12-hour'
    ]

    return (
      <React.Fragment>
        <Setting id="name"
                 deviceId={this.state.deviceId}
                 settingDbKey="name"
                 heading="name"
                 bodyBig={this.state.deviceName}
                 bodySmall={deviceInfo}
                 currentValue={this.state.deviceName}
                 inputType="text"
                 settingUpdated={this.state.settingUpdated} />
        <Setting id="location"
                 deviceId={this.state.deviceId}
                 settingDbKey="location"
                 heading="forecast location"
                 bodyBig={this.state.location}
                 bodySmall={this.state.address}
                 currentValue={this.state.location}
                 inputType="text"
                 settingUpdated={this.state.settingUpdated} />
        <Setting id="timezone"
                 deviceId={this.state.deviceId}
                 settingDbKey="timezone"
                 heading="timezone"
                 bodyBig={this.state.timezone}
                 currentValue={this.state.timezone}
                 inputType="dropdown"
                 options={timezones}
                 settingUpdated={this.state.settingUpdated} />
        <Setting id="update"
                 deviceId={this.state.deviceId}
                 settingDbKey="update"
                 heading="updates"
                 bodyBig={updateOptions[parseInt(this.state.update)]}
                 currentValue={this.state.update}
                 inputType="radio"
                 options={updateOptions} />
        <Setting id="zoom"
                deviceId={this.state.deviceId}
                settingDbKey="zoom"
                heading="zoom"
                bodyBig={twelveHourZoom[parseInt(this.state.zoom)]}
                currentValue={this.state.zoom}
                inputType="segmented"
                options={twelveHourZoom} />
      </React.Fragment>
    )
  }

  setRedirect = () => {
    this.setState({ redirect: true })
  }

  render() {
    // Redirect to device list if settings page is loaded without receiving a
    // location state data from the dashboard. Also redirect if the device
    // was removed for this user.
    if (this.state.redirect) {
      return <Redirect to="/" />
    }

    if (!this.state.dataLoaded) {
      return <div />
    }

    return (
      <React.Fragment>
        <header>
          <div className="wrapper">
            <Link to="/" className="back-nav">back to dashboard</Link>
            <h2>device settings</h2>
          </div>
        </header>
        <div className="wrapper">
          <ul id="settings">
            {this.createSettings()}
          </ul>
          <div id="settings-actions">
            <RebootDevice deviceId={this.state.deviceId} />
            <RemoveDevice deviceId={this.state.deviceId}
                          removeListeners={this.removeListeners}
                          setRedirect={this.setRedirect} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
    }
  }

  handleEditClick = (event) => {
    if (!this.state.isEditable) {
      this.setState({ isEditable: true })
    }
  }

  handleCancelEditClick = (event) => {
    this.setState({ isEditable: false })
  }

  afterSubmit = () => {
    this.setState({ isEditable: false })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isEditable ? (
          <SettingInput id={this.props.id}
                        deviceId={this.props.deviceId}
                        heading={this.props.heading}
                        currentValue={this.props.currentValue}
                        settingDbKey={this.props.settingDbKey}
                        inputType={this.props.inputType}
                        options={this.props.options ? this.props.options : null}
                        handleCancelEditClick={this.handleCancelEditClick}
                        afterSubmit={this.afterSubmit} />
        ) : (
          <SettingDisplay id={this.props.id}
                          heading={this.props.heading}
                          bodyBig={this.props.bodyBig}
                          bodySmall={this.props.bodySmall ? this.props.bodySmall : null}
                          handleEditClick={this.handleEditClick} />
        )}
      </React.Fragment>
    )
  }
}

class SettingInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentValue
    }
  }

  handleInputChange = (event) => {
    this.setState({ value: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const settingRef = firebase.fsDb.collection('devices').doc(this.props.deviceId);
    settingRef.update({
      [this.props.settingDbKey]: this.state.value.toString()
    }).then(res => {
      this.props.afterSubmit();
    })
  }

  ///////////////////////////////////
  // SEGMENTED CONTROLLER HANDLERS //
  ///////////////////////////////////

  handleInputChangeSegmented = (event) => {
    this.setState({ value: 0 });
  }

  _onTouchStart = (event) => {
    console.log('touch start', event.target.value)
    this.setState({ value: event.target.value })
  }

  _onTouchEnd = (event) => {
    console.log('touch end', event.target.value);
    this.setState({ value: 0 })
  }

  labelOnMouseDown = (event) => {
    this.setState({ value: event.target.previousSibling.value })
    const settingRef = firebase.fsDb.collection('devices').doc(this.props.deviceId);
    settingRef.update({
      [this.props.settingDbKey]: event.target.previousSibling.value.toString()
    })
  }

  labelOnMouseUp = (event) => {
    this.setState({ value: 0 })
    const settingRef = firebase.fsDb.collection('devices').doc(this.props.deviceId);
    settingRef.update({
      [this.props.settingDbKey]: '0'
    })
  }

  createInput = () => {

    ////////////////
    // TEXT FIELD //
    ////////////////
    if (this.props.inputType === 'text') {
      return (
        <input type="text"
               name={this.props.id}
               value={this.state.value}
               onChange={this.handleInputChange}
               autoComplete="off"
               disabled={this.state.disabled && "disabled"} />
      )

    //////////////
    // DROPDOWN //
    //////////////
    } else if (this.props.inputType ===  'dropdown') {
      let options = [];
      this.props.options.forEach((option, i) => {
        options.push(
          <option name={this.props.id}
                  value={option}
                  autoComplete="off"
                  key={this.props.id + "-option-" + i}
                  disabled={this.state.disabled}
          >{option}</option>
        )
      })
      return (
        <select value={this.state.value}
                onChange={this.handleInputChange}
        >
          {options}
        </select>
      )

    ///////////////////
    // RADIO BUTTONS //
    ///////////////////
    } else if (this.props.inputType === 'radio') {
      let options = [];
      this.props.options.forEach((option, i) => {
        options.push(
          <div className='settings-radio'
               key={this.props.id + "-option-" + i}>
            <input type="radio"
                   id={this.props.id + '-' + i}
                   value={i}
                   onChange={this.handleInputChange}
                   checked={i === parseInt(this.state.value)} />
            <label htmlFor={this.props.id + '-' + i}>{this.props.options[i]}</label>
          </div>
        )
      })
      return options;

    //////////////////////////
    // SEGMENTED CONTROLLER //
    //////////////////////////
    } else if (this.props.inputType === 'segmented') {
      let optionsToRender = []
      this.props.options.forEach((option, i) => {
        optionsToRender.push(
          <li   className='settings-segmented-child settings-radio'
                key={`${this.props.id}-option-${i}`}>
            <input  type="radio"
                    id={`${this.props.id}-${i}`}
                    value={i}
                    checked={i === parseInt(this.state.value)}
                    onChange={this.handleInputChangeSegmented}
                    // Touch Handlers
                    onMouseDown={this._onTouchStart}
                    onTouchStart={this._onTouchStart}
                    onMouseUp={this._onTouchEnd}
                    onTouchEnd={this._onTouchEnd}
            />

            <label  htmlFor={`${this.props.id}-${i}`}
                    // Touch Handlers
                    onMouseDown={this.labelOnMouseDown}
                    onTouchStart={this.labelOnMouseDown}
                    onMouseUp={this.labelOnMouseUp}
                    onTouchEnd={this.labelOnMouseUp}

            >{this.props.options[i]}</label>
          </li>
        )
      })

      return (
        <ol className='settings-segmented'>{optionsToRender}</ol>
      )
    }
  }

  render() {

    return (
      <li className="settings-field" key={this.props.id}>
        <form onSubmit={this.handleSubmit}
              name="name">
          <h3>{this.props.heading}</h3>
          <div className="settings-input">
            {this.createInput()}
          </div>
          <div className="form-button-row">
            <button className="secondary"
                    type="button"
                    onClick={this.props.handleCancelEditClick}
            >cancel</button>
            <button className="primary"
                    name="save"
                    type="submit"
            >apply</button>
          </div>
        </form>
      </li>
    )
  }
}

const SettingDisplay = (props) => {
  return (
    <React.Fragment>
      <li className="settings-field" key={props.id}>
        <div className="content">
          <h3>{props.heading}</h3>
          <p className="primary">{props.bodyBig}</p>
          {props.bodySmall && <p className="secondary">{props.bodySmall}</p>}
        </div>
        <div>
          <button className="small" type="button" onClick={props.handleEditClick}>edit</button>
        </div>
      </li>
    </React.Fragment>
  )
}

class RebootDevice extends React.Component {

  handleClick = () => {
    const confirmReboot = window.confirm("Reboot device now?");
    if (confirmReboot) {
      const deviceRef = firebase.fsDb.collection('devices').doc(this.props.deviceId);
      deviceRef.update({'reboot': 1});
    }
  }

  render() {
    return (
      <button className="secondary"
              onClick={this.handleClick}
      >reboot device</button>
    )
  }
}

class RemoveDevice extends React.Component {

  handleClick = () => {
    const confirmDelete = window.confirm("Remove this device?");
    if (confirmDelete) {
      // Remove database listeners to avoid errors from searching for deleted
      // device data.
      this.props.removeListeners();
      const uid = firebase.auth().currentUser.uid;
      // Get list of user's devices.
      const userRef = firebase.database().ref('users/' + uid + '/devices');
      userRef.once('value', (devicesSnapshot) => {
        // Iterate through each device and find matching device ID.
        devicesSnapshot.forEach((deviceSnapshot) => {
          if (this.props.deviceId === deviceSnapshot.val().id) {
            const key = deviceSnapshot.key;
            const devicePath = 'users/' + uid + '/devices/' + key;
            const deviceRef = firebase.database().ref(devicePath);
            deviceRef.remove();
            this.props.setRedirect();
          }
        })
      });
    }
  }

  render() {
    return (
      <button className="secondary warning"
              onClick={this.handleClick}
      >remove device</button>
    )
  }
}

export default Settings;
