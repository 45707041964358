import React from 'react';
import {Redirect} from 'react-router-dom';

const firebase = require('./firebase');

class AddNewDevice extends React.Component {
  constructor(props) {
    super(props);

    let backNav = false;
    if (props.location.state !== undefined && props.location.state.backNav !== undefined) {
      backNav = props.location.state.backNav;
    }

    this.state = {
      deviceId1: '',
      deviceId2: '',
      deviceName: '',
      deviceLocation: '',
      showInstructions: false,
      redirect: false, // Triggers redirect back to '/'
      deviceRef: null,
      backNav: backNav,
    }
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })

    if (event.target.name === 'deviceId1') {
      if (event.target.value.length > 3) {
        document.getElementById('id-input-2').focus();
      }
    }
  }

  addDevice = () => {
    var newDeviceId = this.state.deviceId1 + this.state.deviceId2;
    newDeviceId = newDeviceId.toUpperCase()

    // Get list of users's current devices.
    const uid = firebase.auth().currentUser.uid;

    let userDocRef = firebase.fsDb.collection('users').doc(uid)
    userDocRef.get().then(snapshot => {
      if (!snapshot.exists) {
        userDocRef.set({
          devices: [newDeviceId]
        })
      } else {
        console.log(snapshot.data().devices)
        userDocRef.update({
          devices: firebase.Firestore.FieldValue.arrayUnion(newDeviceId)
        })
      }
      this.setState({ redirect: true });
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const deviceId = this.state.deviceId1 + this.state.deviceId2;
    if (deviceId === '' || deviceId.length !== 8) {
      alert('Enter an 8-digit device ID.');
    } else if (this.state.deviceName === '') {
      alert('Enter device description.');
    } else if (this.state.deviceLocation === '' ) {
      alert('Enter a forecast location.');
    } else {
      this.addDevice();
    }
  }

  componentWillUnmount = () => {
    if (this.state.deviceRef) {
      this.state.deviceRef.off('value');
    }
  }

  handleCancel = () => {
    this.setState({ redirect: true })
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to="/" />
    }

    return (
      <React.Fragment>
        <header>
          <div className="wrapper">
            <h2>add new device</h2>
          </div>
        </header>
        <div className="wrapper">
          <form id="add-device" onSubmit={this.handleSubmit}>
            <h3>Device ID</h3>
            <div className="device-id-input">
              <input type="text"
                     id="id-input-1"
                     name="deviceId1"
                     value={this.state.deviceId1}
                     onChange={this.handleInputChange}
                     size="4"
                     placeholder="0000"
                     autoComplete="off" />
              <span className="spacer">-</span>
              <input type="text"
                     id="id-input-2"
                     name="deviceId2"
                     value={this.state.deviceId2}
                     onChange={this.handleInputChange}
                     size="4"
                     placeholder="0000"
                     autoComplete="off" />
            </div>
            <div className="device-description">
              <h3>Device Description</h3>
              <div>
                <input type="text"
                        id="device-name"
                        name="deviceName"
                        value={this.state.deviceName}
                        onChange={this.handleInputChange}
                        placeholder="e.g. Vermont Living Room"
                        autoComplete="off" />
              </div>
            </div>
            <div className="device-location">
              <h3>Forecast Location</h3>
              <div>
                <input type="text"
                        id="device-location"
                        name="deviceLocation"
                        value={this.state.deviceLocation}
                        onChange={this.handleInputChange}
                        placeholder="Location name or address"
                        autoComplete="off" />
              </div>
            </div>
            <div className="form-button-stack">
              <button type="submit">submit</button>
              <button className="secondary"
                      type="button"
                      onClick={this.handleCancel}>cancel</button>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default AddNewDevice;
